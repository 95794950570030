import moment from 'moment'
import { LocalStorageKeys } from './constants'

/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return []
  }

  return data.map(val => {
    return {
      ...val,
      value: val?.[valueKey],
      label: val?.[labelKey]
    }
  })
}

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = string => {
  if (!string) {
    return string
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
  if (hex) {
    let tempHex = hex.replace('#', '')
    let r = parseInt(tempHex.substring(0, 2), 16)
    let g = parseInt(tempHex.substring(2, 4), 16)
    let b = parseInt(tempHex.substring(4, 6), 16)

    return `rgba(${r},${g},${b},${opacity / 100})`
  }
  return null
}

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

// Check the versions
export let semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ['0', '0', '0']
  const versionsB = versionB ? versionB.split(/\./g) : ['0', '0', '0']

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(names => {
      for (const name of names) {
        caches.delete(name)
      }
    })
  }
  // delete browser cache and hard reload
  window.location.reload(true)
}

// To get distance between two lattitude and longitude
export const distance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    if (unit === 'K') {
      dist = dist * 1.609344
    }
    if (unit === 'N') {
      dist = dist * 0.8684
    }
    return dist
  }
}

export const hoursToMinutes = (durationHours, durationMinutes) => {
  return parseInt(durationHours) * 60 + parseInt(durationMinutes)
}

export const minutesToHours = durationTime => {
  if (durationTime) {
    let durationHours = parseInt(durationTime / 60)
    durationHours = { label: durationHours?.toString()?.padStart(2, '0') }

    let durationMinutes = parseInt(durationTime % 60)
    durationMinutes = { label: durationMinutes?.toString()?.padStart(2, '0') }

    return { durationHours, durationMinutes }
  } else {
    return {}
  }
}

export const getWeekStart = startDate => {
  return moment(startDate)
    .add(1, 'days')
    .toISOString()
}

export const getWeekEnd = endDate => {
  return moment(endDate)
    .subtract(1, 'days')
    .endOf('day')
    .toISOString()
}

export const getCurrentDateKey = currentDay => {
  return `${currentDay.MM}/${currentDay.DD}/${currentDay.YYYY}`
}

export const DateFormatChange = date => {
  return moment(date).format('DD MMM `YY')
}

/// get start date of last week
export const getStartDateFromLastWeek = (Date, index) => {
  return parseDate(moment(Date).subtract(index, 'days'))
}

/// get end data of next week
export const getEndDateFromNextWeek = (Date, index) => {
  return parseDate(moment(Date).add(index, 'days'))
}

/// get next week
export const getNextWeek = (Date, diff = 1) => {
  return parseDate(moment(Date).add(diff, 'weeks'))
}

/// get current week
export const getCurrentWeek = (Date, currentDateIndex) => {
  return {
    startDate: parseDate(moment(Date).subtract(currentDateIndex, 'days')),
    endDate: parseDate(moment(Date).add(7 - currentDateIndex, 'days'))
  }
}

/// get before week
export const getBeforWeek = (Date, diff = 1) => {
  return parseDate(moment(Date).subtract(diff, 'weeks'))
}

/// get next day
export const getNextDay = (Date, diff = 1) => {
  return parseDate(moment(Date).add(diff, 'days'))
}

/// get before day
export const getBeforeDay = (Date, diff = 1) => {
  return parseDate(moment(Date).subtract(diff, 'days'))
}

/// get start day of month
export const getStartDateOfMonth = Date => {
  return parseDate(moment.utc(Date).startOf('months'))
}

/// parsing moment date into seperate format
export const parseDate = date => {
  /// All keys used are of standard date formats
  return {
    DD: date.format('DD'), // Formats to day 01-31
    MM: date.format('MM'), // Formats to month 01 - 12
    YYYY: date.format('YYYY'), /// Formats to year 2020 - 2030
    DDMMYY: date.format('DD MMM `YY'), /// Formats to (22 12 `97)
    DDDD: date.format('dddd'), /// Formats to Monday,Tuesday
    DDD: date.format('ddd'), /// Formats to Mon,Tue
    DATE: date.format('DD-MMM-YY'), /// Formates to 22-Mon-2022
    MMM: date.format('MMM') /// Formats to May,Apr
  }
}

export const clearAllLocastorage = () => {
  let appVersion = localStorage.getItem(LocalStorageKeys.version)
  localStorage.clear()
  localStorage.setItem(LocalStorageKeys.version, appVersion)
}
