import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const DivisionApi = createApi({
    reducerPath: "DivisionApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Division"],
    endpoints: (builder) => ({
        getDivision: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Division/uiforms/formattributes`,
            providesTags: ["Division"]
        }),
        addDivision: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Division/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Division"]
        }),
        importDivision: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Division/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Division"]
        }),
        updateDivision:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Division/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Division"]
        }),
        filterDivision: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Division/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Division"]
        }),
        deleteDivision: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Division/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Division"]
        }),
        getDivisionAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Division/uiforms/autoCount`,
            providesTags: ["Division"]
            })
        })
    })
export const { 
    useGetDivisionQuery, useFilterDivisionQuery, useAddDivisionMutation, useUpdateDivisionMutation, useImportDivisionMutation, useDeleteDivisionMutation, useGetDivisionAutoCountQuery
//Dynamic query
} = DivisionApi