import { io } from "socket.io-client";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  admin: "admin",
  manager: "manager",
  contractUser: "user",
  superAdmin: "super_admin",
};

export const MangerDecision = {
  approved: "Approved on",
  rejected: "Rejected on",
};
/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */

const url = window.location.hostname;

let domainName = "";

if (url.includes(".")) {
  const domainParts = url.split(".");
  domainName = domainParts.slice(-2).join(".");
} else {
  domainName = url;
}
let mainDomain = url?.match(/^https?:\/\/([^/]+)/i)?.[1];

export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
  userRole: "user_role",
  userRoleData: "user_role_data",
  userId: "user_id",
  userEmail: "user_email",
  userPhone: "user_phone",
  countryCode: "country_code",
  userName: "user_name",
  userRoute: "user_routes",
  isSidebar: "is_sidebar",
  isNavbar: "is_navbar",
  entityId: "entity_id",
  reportingData: "reporter_data",
  countryMobileCode: "country_mobile_code",
  clientData: "client_data",
  userImgUrl: "user_img_url",
  clientImgUrl: "client_img_url",
  permissionRoleData: "permission_role_data",
  reportingTo: "reporting_to",
  employeeid: "employeeid",
  employeelocation: "employeelocation",
  gender: "gender",
  managerid: "managerid",
  managername: "managername",
  manageremail: "manageremail",
  managerphone: "managerphone",
  openapplication: "openapplication",
  // domain: window.location.hostname.split(".").splice(0, 1).join(),
  domainname: domainName,
  mainDomain: mainDomain,
  domain: "stage1-engage",
  // domain: "demo-aravind",
  // domain: "demo-balaji",
  // domain: "demo-admin",
  //domain: "demo-whatsappclient",
  //domain: "demo-demohrtest",
  // domain: "demo-subash28",
  // domain: "demo-demohrtest",
};
export const socket = io("https://integrations.kovaion.ai", {
  path: "/websocket/socket.io",
});

// To build version string
export const VersionFetchURL = `${window.location.protocol}//${
  window.location.hostname
}${
  window.location.port ? ":" + window.location.port : ""
}/meta.json?v=${+new Date()}`;

export const userRoleDisplayName = {
  [UserRoles.manager]: "manager",
  [UserRoles.admin]: "admin",
  [UserRoles.contractUser]: "user",
  [UserRoles.superAdmin]: "Super Admin",
};

export const userRoleEntityName = {
  [UserRoles.manager]: "# Manager",
  [UserRoles.admin]: "# Client Admin",
  [UserRoles.contractUser]: "# contract user",
};

export const drawerWidth = 340;

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const MONTHS = [
  { id: "Jan", label: "Jan" },
  { id: "Feb", label: "Feb" },
  { id: "Mar", label: "Mar" },
  { id: "Apr", label: "Apr" },
  { id: "May", label: "May" },
  { id: "Jun", label: "Jun" },
  { id: "Jul", label: "Jul" },
  { id: "Aug", label: "Aug" },
  { id: "Sep", label: "Sep" },
  { id: "Oct", label: "Oct" },
  { id: "Nov", label: "Nov" },
  { id: "Dec", label: "Dec" },
];

export const perDayLogHours = 8;
export const durationHoursList = [
  { id: "01", label: "01" },
  { id: "02", label: "02" },
  { id: "03", label: "03" },
  { id: "04", label: "04" },
  { id: "05", label: "05" },
  { id: "06", label: "06" },
  { id: "07", label: "07" },
  { id: "08", label: "08" },
  { id: "09", label: "09" },
  { id: "10", label: "10" },
  { id: "11", label: "11" },
  { id: "12", label: "12" },
];
export const durationMinutesList = [
  { id: "01", label: "00" },
  { id: "02", label: "15" },
  { id: "03", label: "30" },
  { id: "04", label: "45" },
];

export const workStatus = {
  rejected: [
    {
      date: "30 May 22",
      name: "Steven",
      startDate: "25 Apr`22",
      endDate: "30 Apr`22",
      isRejected: true,
    },
  ],
  pending: [
    {
      date: "30 May 22",
      startDate: "25 Apr`22",
      endDate: "30 Apr`22",
    },
  ],
};

export const personTableData = {
  Header: [
    "Person name",
    "Employee id",
    "Email id",
    "Manager",
    "Role",
    "Project",
    "status",
    " ",
  ],
  tableData: [
    {
      type: ["TEXT"],
      name: "name",
      align: "left",
      width: 120,
    },
    {
      type: ["TEXT"],
      name: "emp_id",
      align: "left",
      width: 130,
    },
    {
      type: ["TEXT"],
      name: "email",
      align: "left",
    },
    {
      type: ["TEXT"],
      name: "manager",
      align: "left",
      width: 120,
    },
    {
      type: ["BADGE"],
      name: "role",
      align: "left",
    },
    {
      type: ["TEXT"],
      name: "project",
      align: "left",
      width: "100px",
    },
    {
      type: ["SWITCH"],
      name: "is_active",
      align: "center",
      width: 50,
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "left",
      width: 10,
    },
  ],
};

export const clientListData = {
  Header: ["Entity", "Db Config type", "Subdomain"],
  tableData: [
    {
      type: ["PROFILE"],
      name: "name",
      align: "left",
      width: 120,
    },
    {
      type: ["TEXT"],
      name: "isGeneric",
      align: "left",
    },
    {
      type: ["TEXT"],
      name: "subDomain",
      align: "left",
      width: 120,
    },
  ],
};

export const domainRecordsData = {
  Header: ["Type", "TTL", "name", "value", "status"],
  tableData: [
    {
      type: ["TEXT"],
      name: "type",
      align: "left",
      width: 120,
    },
    {
      type: ["TEXT"],
      name: "ttl",
      align: "left",
    },
    {
      type: ["CLIPBOARD"],
      name: "name",
      align: "left",
      width: 120,
    },
    {
      type: ["CLIPBOARD"],
      name: "value",
      align: "left",
      width: 200,
    },
    {
      type: ["VERIFY"],
      name: "isVerified",
      align: "left",
    },
  ],
};

export const projectTableData = {
  Header: [
    "Project name",
    "Project id",
    "Project Description",
    "Start date & End date",
    "Tasks",
    "Persons",
    "status",
    " ",
  ],

  tableData: [
    {
      type: ["TEXT"],
      name: "name",
      align: "left",
      width: 120,
    },
    {
      type: ["TEXT"],
      name: "project_id",
      align: "left",
    },
    {
      type: ["DESCRIPTION"],
      name: "description",
      align: "left",
      width: 150,
    },
    {
      type: ["TEXT"],
      name: "date",
      align: "left",
      width: 180,
    },
    {
      type: ["SIDEMENU"],
      name: "tasks",
      align: "center",
    },
    {
      type: ["SIDEMENU"],
      name: "members",
      align: "center",
    },
    {
      type: ["SWITCH"],
      name: "is_active",
      align: "center",
      width: 50,
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "left",
      width: 10,
    },
  ],
};
export const formTableData = {
  Header: ["TITLE", "DESCRIPTION", "EDIT FIELD"],
  tableData: [
    {
      type: ["TEXT"],
      name: "title",
      align: "left",
      width: 100,
    },
    {
      type: ["DESCRIPTION"],
      name: "description",
      align: "left",
      width: 100,
    },

    // {
    //   type: ['VERIFY'],
    //   name: 'published',
    //   align: 'left'
    // },
    {
      type: ["MENU"],
      name: "menu",
      align: "left",
      width: 100,
    },
  ],
};

export const dashboardTableData = {
  Header: [
    "JOBID",
    "JOB TITLE",
    "CANDIDATE NAME",
    "CANDIDATE OWNER",
    "PHASE & STATUS",
    "PANEL",
    "RECRUITER PRIMARY",
    "INTERVIEW DATE",
    "NOTICE PERIOD",
  ],
  tableData: [
    {
      type: ["TEXT"],
      name: "jobid",
      align: "left",
      width: 100,
    },
    {
      type: ["TEXT"],
      name: "jobrequisitionname",
      align: "left",
      width: 100,
    },
    {
      type: ["TEXT"],
      name: ["candidateid", "candidatefirstname"],
      align: "center",
      width: 100,
      content: "merge",
    },
    {
      type: ["TEXT"],
      name: "createdBy",
      align: "left",
      width: 100,
    },
    {
      type: ["TEXT"],
      name: ["phase", "status"],
      align: "center",
      width: 100,
    },
    {
      type: ["TEXT"],
      name: "panel",
      align: "center",
      width: 100,
    },
    {
      type: ["TEXT"],
      name: "recruiterprimary",
      align: "center",
      width: 100,
    },
    {
      type: ["DATEPICKER"],
      name: "interviewdate",
      align: "center",
      width: 100,
    },
    {
      type: ["DESCRIPTION"],
      name: "NP",
      align: "center",
      width: 100,
    },
  ],
};

export const DivisiontableData = {
  Header: ["DIVISION", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "division", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const RequisitionownertableData = {
  Header: ["REQUISITION_OWNER", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "requisitionowner", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const RequisitionstatetableData = {
  Header: ["REQUISITIONSTATE", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "requisitionstate", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const CandidatelocationtableData = {
  Header: ["CANDIDATE_LOCATION", "DESCRIPTION", "ACTION"],
  tableData: [
    {
      type: ["TEXTFIELD"],
      name: "candidatelocation",
      align: "left",
      width: "",
    },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const RecruitmentphasetableData = {
  Header: ["RECRUITMENT_PHASE", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "recruitmentphase", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const RecruitmentstatetableData = {
  Header: ["RECRUITMENT_STATE", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "recruitmentstate", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const CandidateskillstableData = {
  Header: ["CANDIDATE_SKILLS", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "candidateskills", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const CandidatestableData = {
  Header: [
    "CANDIDATE ID",
    "FIRST NAME",
    "LAST NAME",
    "PHONE NUMBER",
    "EMAIL ID",
    "TECHNOLOGIES",
    "CURRENT LOCATION",
    "T.E",
    "R.E",
    "CTC",
    "ECTC",
    "NP",
    "REMARKS",
    "UPLOAD RESUME",
    "REASON FOR CHANGE",
    "CREATION DATE",
    "ACTION",
  ],
  tableData: [
    { type: ["AUTOGEN"], name: "candidateid", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "firstname", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "lastname", align: "left", width: "" },
    { type: ["PHONE"], name: "phonenumber", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "emailid", align: "left", width: "" },
    { type: ["MULTISELECT"], name: "technologies", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "currentlocation", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "te", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "re", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "ctc", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "ectc", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "np", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "remarks", align: "left", width: "" },
    { type: ["FILEUPLOAD"], name: "uploadresume", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "reasonforchange", align: "left", width: "" },
    { type: ["DATEPICKER"], name: "creationdate", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const RecruitmenttableData = {
  Header: [
    "JOB ID",
    "CANDIDATE ID",
    "PANEL",
    "PHASE",
    "STATUS",
    "REASON",
    "UPDATE DATE",
    "ACTION",
  ],
  tableData: [
    { type: ["DROPDOWN"], name: "jobid", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "candidateid", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "panel", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "phase", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "status", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "reason", align: "left", width: "" },
    { type: ["DATEPICKER"], name: "updatedate", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const InterviewertableData = {
  Header: ["INTERVIEWER", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "interviewer", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};

export const ManageEmailConfigData = {
  Header: ["Service", "Email Id", "Password", "Port", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "service", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "emailid", align: "left", width: "" },
    {
      type: ["TEXTFIELD"],
      name: "password",
      subType: "spasword",
      align: "left",
      width: "",
    },
    { type: ["TEXTFIELD"], name: "port", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};

export const RecruitertableData = {
  Header: ["RECRUITER", "RECRUITER EMAIL", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "recruiter", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "recruiteremail", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const RequisitiontableData = {
  Header: [
    "JOB ID",
    "JOB REQUISITION NAME",
    "REQUISITION OWNER",
    "RECRUITER-PRIMARY",
    "RECRUITER-SUPPORT",
    "STATUS",
    "JD NUMBER",
    "TOTAL POSITIONS",
    "JOB OPENING STATUS",
    "UPDATED DATE",
    "REMARKS",
    "CREATION DATE",
    "ACTION",
  ],
  tableData: [
    { type: ["AUTOGEN"], name: "jobid", align: "left", width: "" },
    {
      type: ["TEXTFIELD"],
      name: "jobrequisitionname",
      align: "left",
      width: "",
    },
    { type: ["DROPDOWN"], name: "requisitionowner", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "recruiterprimary", align: "left", width: "" },
    {
      type: ["MULTISELECT"],
      name: "recruitersupport",
      align: "left",
      width: "",
    },
    { type: ["DROPDOWN"], name: "status", align: "left", width: "" },
    { type: ["AUTOGEN"], name: "jdnumber", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "totalpositions", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "jobopeningstatus", align: "left", width: "" },
    { type: ["DATEPICKER"], name: "updateddate", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "remarks", align: "left", width: "" },
    { type: ["DATEPICKER"], name: "creationdate", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const JobstatustableData = {
  Header: ["JOB STATUS", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "jobstatus", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const SourcetableData = {
  Header: ["SOURCE NAME", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "sourcename", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const CustomerstableData = {
  Header: [
    "COMPANY NAME",
    "ACCOUNT NAME",
    "CUSTOMER NAME",
    "DESIGNATION OF THE CUSTOMER",
    "CUSTOMER EMAIL ADDRESS",
    "ACTION",
  ],
  tableData: [
    { type: ["TEXTFIELD"], name: "companyname", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "accountname", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "customername", align: "left", width: "" },
    {
      type: ["TEXTFIELD"],
      name: "designationofthecustomer",
      align: "left",
      width: "",
    },
    {
      type: ["TEXTFIELD"],
      name: "customeremailaddress",
      align: "left",
      width: "",
    },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const ProfileinfotableData = {
  Header: [
    "FIRST NAME",
    "LAST NAME",
    "DATE OF BIRTH",
    "CURRENT ADDRESS",
    "PERMANENT ADDRESS",
    "PHONE NUMBER",
    "PERSONAL EMAIL ID",
    "PASSPORT NUMBER",
    "DATE OF ISSUE",
    "DATE OF EXPIRY",
    "ACTION",
  ],
  tableData: [
    { type: ["TEXTFIELD"], name: "firstname", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "lastname", align: "left", width: "" },
    { type: ["DATEPICKER"], name: "dateofbirth", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "currentaddress", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "permanentaddress", align: "left", width: "" },
    { type: ["PHONE"], name: "phonenumber", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "personalemailid", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "passportnumber", align: "left", width: "" },
    { type: ["DATEPICKER"], name: "dateofissue", align: "left", width: "" },
    { type: ["DATEPICKER"], name: "dateofexpiry", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const RolestableData = {
  Header: ["NAME", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "name", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const ManageuserstableData = {
  Header: ["NAME", "EMP ID", "ROLE NAME", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "name", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "emp_id", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "rolename", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const CategoriestableData = {
  Header: ["CATEGORIES", "DESCRIPTION", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "category", align: "left", width: "" },
    { type: ["TEXTAREA"], name: "description", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};

export const ManageMenutableData = {
  Header: [
    "NAME",
    "MENU TYPE",
    "SEQUENCENUMBER",
    "MENU ACTION",
    "ICON",
    "ACTION",
  ],
  tableData: [
    { type: ["TEXTFIELD"], name: "displayName", align: "left", width: "" },
    { type: ["DROPDOWN"], name: "menuType", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "order", align: "left", width: "" },
    { type: ["SWITCH"], name: "actionable", align: "left", width: "" },
    { type: ["IMAGE"], name: "icon", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};

export const Employmenttype = [
  { label: "Part Time", value: "Part Time" },
  { label: "Full Time", value: "Full Time" },
];

export const Conjunction = [
  { label: "AND", value: "AND" },
  { label: "OR", value: "OR" },
];
export const OptionList = [
  { label: "EQUALS", value: "EQUALS" },
  { label: "NOTEQUAL", value: "NOTEQUAL" },
  { label: "CONTAINS", value: "CONTAINS" },
  { label: "NOTCONTAINS", value: "NOTCONTAINS" },
  { label: "STARTWITH", value: "STARTWITH" },
];
export const OptionEmail = [
  { label: "EQUALS", value: "EQUALS" },
  { label: "NOTEQUAL", value: "NOTEQUAL" },
];
export const OptionListTextArea = [
  // { label: "MIN-LENGTH", value: "MIN-LENGTH" },
  // { label: "MAX-LENGTH", value: "MAX-LENGTH" },
  { label: "CONTAINS", value: "CONTAINS" },
  { label: "NOTCONTAINS", value: "NOTCONTAINS" },
  { label: "STARTSWITH", value: "STARTSWITH" },
];
export const OptionListDate = [
  { label: "EQUALS", value: "EQUALS" },
  { label: "NOTEQUAL", value: "NOTEQUAL" },
  { label: "AFTER", value: "AFTER" },
  { label: "BEFORE", value: "BEFORE" },
];
export const OptionRadioData = [
  { label: "YES", value: "YES" },
  { label: "NO", value: "NO" },
];
export const OptionDropDown = [
  { label: "EQUALS", value: "EQUALS" },
  { label: "NOTEQUAL", value: "NOTEQUAL" },
];
export const OptionListNumber = [
  { label: "EQUALS", value: "EQUALS" },
  { label: "NOTEQUAL", value: "NOTEQUAL" },
  { label: "NOT BETWEEN", value: "NOT BETWEEN" },
  { label: "BETWEEN", value: "BETWEEN" },
  { label: "GREATERTHANOREQUAL", value: "GREATERTHANOREQUAL" },
  // { label: "IN", value: "IN" },
  { label: "LESSTHAN", value: "LESSTHAN" },
  { label: "GREATERTHAN", value: "GREATERTHAN" },

  { label: "LESSTHANOREQUAL", value: "LESSTHANOREQUAL" },
];
export const DocumentData = {
  Header: ["Title", "Contributors", "Last Updated", "Status", "Tags"],

  tableData: [
    {
      type: ["LINK"],
      name: "name",
      align: "center",
      width: 0,
    },
    {
      type: ["TEXT"],
      name: "contributor",
      align: "left",
      width: 150,
    },
    {
      type: ["TIME"],
      name: "updatedon",
      align: "left",
      width: 120,
    },
    {
      type: ["BADGE"],
      name: "status",
      align: "left",
      width: 120,
    },
    {
      type: ["TEXT"],
      name: "Tags",
      align: "center",
      width: 120,
    },
  ],
};

export const VersionhistorytableData = {
  Header: [
    "APP TITLE",
    "VERSION TITLE",
    "VERSION NUMBER",
    "RELEASE TYPE",
    "DATE",
    "STATUS",
  ],
  tableData: [
    { type: ["TEXTFIELD"], name: "apptitle", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "versiontitle", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "versionnumber", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "releasetype", align: "left", width: "" },
    { type: ["DATEPICKER"], name: "date", align: "left", width: "" },
    { type: ["VERSIONSTATUS"], name: "status", align: "left", width: "" },
  ],
};
export const TemplatetableData = {
  Header: ["TEMPLATE NAME", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "templatename", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};
export const ContactstableData = {
  Header: ["NAME", "MOBILE", "ACTION"],
  tableData: [
    { type: ["TEXTFIELD"], name: "name", align: "left", width: "" },
    { type: ["TEXTFIELD"], name: "mobile", align: "left", width: "" },
    { type: ["MENU"], name: "menu", align: "left", width: 10 },
  ],
};

export const BluePrintTable = {
  Header: ["Workflow Name", "Workflow Type", "Created Time", "Action"],
  tableData: [
    {
      type: ["LINK"],
      name: "workflowname",
      align: "center",
      width: 80,
    },
    {
      type: ["TEXT"],
      name: "workflowtype",
      align: "center",
      width: 100,
    },
    {
      type: ["TEXT"],
      name: "time",
      align: "center",
      width: 100,
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "left",
      width: 10,
    },
  ],
};
export const templateTableData = {
  Header: ["Template Name", "Category", "Status", "Language", "Action"],
  tableData: [
    {
      type: ["TEXT"],
      name: "name",
      align: "center",
      width: 200,
    },
    {
      type: ["TEXT"],
      name: "category",
      align: "center",
      width: 200,
    },
    {
      type: ["TEXT"],
      name: "status",
      align: "center",
      width: 200,
    },
    {
      type: ["TEXT"],
      name: "language",
      align: "center",
      width: 200,
    },
    {
      type: ["MENU"],
      name: "language",
      align: "center",
      width: 200,
    },
  ],
};
export const WhatsappflowData = {
  Header: [
    "Flow Name",
    "Description",
    "Created Time",
    "Status",
    "Default",
    "Actions",
  ],
  tableData: [
    {
      type: ["LINK"],
      name: "flowname",
      align: "center",
      width: 80,
    },
    {
      type: ["TEXT"],
      name: "description",
      align: "center",
      width: 100,
    },
    {
      type: ["TIME"],
      name: "createdAt",
      align: "center",
      width: 100,
    },
    {
      type: ["SWITCH"],
      name: "status",
      align: "center",
      width: 50,
    },
    {
      type: ["SWITCH"],
      name: "default",
      align: "center",
      width: 50,
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "center",
      width: 100,
    },
  ],
};
export const personTableData1 = {
  Header: [
    "",
    // 'Profile',
    "Profile",
    "First Name",
    "LastName",
    // 'Countrycode',
    "Phone",
    "DOB",
    // 'BroadCast',
    "Contact Type",
    "Actions",
  ],
  tableData: [
    {
      type: ["CHECKBOX"],
      align: "center",
      width: 100,
    },
    // {
    //   type: ['PROFILE'],
    //   name: 'personImageUrl',
    //   align: 'center',
    //   width: ''
    // },
    {
      type: ["PROFILE"],
      name: "name",
      align: "left",
      width: 80,
    },
    {
      type: ["TEXT"],
      name: "firstname",
      align: "center",
      width: 80,
    },
    {
      type: ["TEXT"],
      name: "lastname",
      align: "center",
      width: 100,
    },
    // {
    //   type: ['TEXT'],
    //   name:   'country_code',
    //   align: 'center',
    //   width: ''
    // },
    {
      type: ["TEXT"],
      name: "phone",
      align: "center",
      width: 100,
    },
    {
      type: ["DATE"],
      name: "dob",
      align: "center",
      width: 100,
    },
    {
      type: ["TEXT"],
      name: "contacttype",
      align: "center",
      width: 100,
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "center",
      width: 100,
    },
  ],
};
export const broadcastlisttable = {
  Header: [
    "Broadcast Name",
    "Sent",
    "Delivered",
    "Read",
    "Scheduled time",
    "Status",
    "Activity",
  ],
  tableData: [
    {
      type: ["TEXT"],
      name: "broadcast_name",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "send",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "delivered",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "read",
      align: "center",
      width: "",
    },
    {
      type: ["TIME"],
      name: "schedule_time_timestamp",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "status",
      align: "center",
      width: "",
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "center",
      width: 100,
    },
  ],
};
export const ssoTable = {
  Header: ["Client_Id", "Client_Secret", "Actions"],
  tableData: [
    {
      type: ["TEXT"],
      name: "clientId",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "clientSecret",
      align: "center",
      width: "",
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "center",
      width: 100,
    },
  ],
};
export const WhatsappConfigTable = {
  Header: [
    "Phone Number ID",
    "WABA ID",
    "Access Token",
    "App Id",
    "Api Version",
    "ACTIONS",
  ],
  tableData: [
    {
      type: ["TEXT"],
      name: "phoneNumberID",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "WABAID",
      align: "center",
      width: "",
    },
    {
      type: ["LONGTEXT"],
      name: "accessToken",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "appId",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "apiVersion",
      align: "center",
      width: "",
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "center",
      width: 100,
    },
  ],
};
export const firebaseConfigTable = {
  Header: ["File Name", "ACTIONS"],
  tableData: [
    {
      type: ["TEXT"],
      name: "fileName",
      align: "center",
      width: "",
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "center",
      width: 100,
    },
  ],
};

export const querylisttable = {
  Header: ["Question", "Answer", "WebUrl", "ImageUrl", "VideoUrl", "Activity"],
  tableData: [
    {
      type: ["TEXT"],
      name: "question",
      align: "center",
      width: 200,
    },
    {
      type: ["TEXT"],
      name: "answer",
      align: "center",
      width: 200,
    },
    {
      type: ["LINK"],
      name: "weburl",
      align: "center",
      width: 100,
    },
    {
      type: ["IMAGEURL"],
      name: "imageurl",
      align: "center",
      width: 100,
    },
    {
      type: ["VIDEOURL"],
      name: "videourl",
      align: "center",
      width: 100,
    },
    {
      type: ["MENU"],
      name: "menu",
      align: "center",
      width: 50,
    },
  ],
};
//Dynamic values

export const KMSDefaultFiles = [
  // "6382968466079c18e9a39c70",
  // "6382985c66079c18e9a39c71",
  // "6382990066079c18e9a39c72",
  // "6382991066079c18e9a39c73",
  // "63829a4366079c18e9a39c74",
  // "638290fb66079c18e9a39c68",
  // "638291aa66079c18e9a39c69",
  // "638291b066079c18e9a39c6a",
  // "6382937b66079c18e9a39c6b",
  // "638293ae66079c18e9a39c6c",
];

export const viewbroadcastlisttable = {
  Header: ["Contact Name", "Phone Number", "Status", "Reason"],
  tableData: [
    {
      type: ["TEXT"],
      name: "name",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "phone",
      align: "center",
      width: "",
    },
    {
      type: ["TEXT"],
      name: "messageStatus",
      align: "center",
      width: "",
    },
    {
      type: ["REASONTEXT"],
      name: "Reason",
      align: "center",
      width: "",
    },
  ],
};

export const StrCard = [
  {
    label: "default",
    value: "default",
  },
  {
    label: "count",
    value: "count",
  },
  {
    label: "unique values",
    value: "unique values",
  },
];

export const IntCard = [
  {
    label: "default",
    value: "default",
  },
  {
    label: "sum",
    value: "sum",
  },
  {
    label: "max",
    value: "max",
  },
  {
    label: "min",
    value: "min",
  },
];
export const IntChart = [
  {
    label: "sum",
    value: "sum",
  },
  {
    label: "count",
    value: "count",
  },
  {
    label: "mean",
    value: "mean",
  },
  {
    label: "median",
    value: "median",
  },
  {
    label: "max",
    value: "max",
  },
  {
    label: "min",
    value: "min",
  },
];
