import { LocalStorageKeys } from './constants'

//Function to validate email
export let ValidateEmail = email => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
 
  return Boolean(re.test(email))

}

export let validatePassword = password => {
  if (!password) {
    return 'Password is required'
  } else if (password?.length < 8) {
    return 'password should be minimum of 8 characters'
  } else if (password?.search(/[a-z]/) === -1) {
    return 'password should contain atleast one lowercase character'
  } else if (password?.search(/[A-Z]/) === -1) {
    return 'password should contain atleast one uppercase character'
  } else if (password?.search(/[0-9]/) === -1) {
    return 'password should contain atleast one number '
  } else {
    return ''
  }
}

export let validateSubdomain = sub_domain => {
  var regex = /^[0-9a-z\_\-]+$/
  if (!sub_domain) {
    return 'Sub domain is required'
  } else if (/\s/.test(sub_domain)) {
    return 'Sub domain should not have spaces'
  } else if(regex.test(sub_domain))  {
    return ''
  }
  else{
    return 'Sub domain should contain only lowercase alphabets,numbers,hyphen,underscore'
  }
}
//Function to check wheather the 'e' is number key event or not based on 'isNumber' key
export let IsNumberKey = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (!(e.which >= 48 && e.which <= 57)) e.preventDefault()
    }
  }
}

export let validatePhoneLength = phone => {
  return phone?.length <= 15
}

export let checkValidVariable = keyName => {
  // eslint-disable-next-line
  let re = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return !Boolean(re.test(keyName))
}

export let validatePhoneCountryCode = phone => {
  return (
    phone.slice(0, 2) ===
    localStorage.getItem(LocalStorageKeys.countryMobileCode)
  )
}

//Function to check wheather the 'e' is number key event and is down or not.
export let IsNumberKeyDown = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (e.which === 38 || e.which === 40) e.preventDefault()
    }
  }
}

//Function to check wheather the 'e' is text key event or not.
export let IsTextKeyDown = (event, isOnlyText) => {
  var inputValue = event.which

  if (isOnlyText) {
    if (
      !(
        (inputValue >= 65 && inputValue <= 90) ||
        (inputValue >= 97 && inputValue <= 122)
      ) &&
      inputValue !== 32 &&
      inputValue !== 0
    ) {
      event.preventDefault()
    }
  }
}
