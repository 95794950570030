import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const RecruiterApi = createApi({
    reducerPath: "RecruiterApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Recruiter"],
    endpoints: (builder) => ({
        getRecruiter: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Recruiter/uiforms/formattributes`,
            providesTags: ["Recruiter"]
        }),
        addRecruiter: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruiter/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruiter"]
        }),
        importRecruiter: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruiter/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruiter"]
        }),
        updateRecruiter:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Recruiter/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Recruiter"]
        }),
        filterRecruiter: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruiter/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruiter"]
        }),
        deleteRecruiter: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Recruiter/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Recruiter"]
        }),
        getRecruiterAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Recruiter/uiforms/autoCount`,
            providesTags: ["Recruiter"]
            })
        })
    })
export const { 
    useGetRecruiterQuery, useFilterRecruiterQuery, useAddRecruiterMutation, useUpdateRecruiterMutation, useImportRecruiterMutation, useDeleteRecruiterMutation, useGetRecruiterAutoCountQuery
//Dynamic query
} = RecruiterApi