import { keyframes } from "@emotion/react";
import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    loadercomp:{
       display:'flex',
       flexDirection:'column',
       justifyContent:'center',
       alignItems:'center',
       height:"15%"
    },
    loaderTop:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        height:'100vh',
        justifyContent:'center'
    },
    loaderfield:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        padding:'40px',
       // textAlign: 'center',
    //     [theme.breakpoints.down("md")]: {
    //         padding:'20px',
    // }
},
    loadercontent:{
        fontFamily:'poppins',
        fontSize:'25px',
        fontWeight:600,
        width:'70%',
        justifyContent:'center',
    //     [theme.breakpoints.down("md")]: {
    //         fontSize:'18px',
    // }
    },
    loadercontenttext:{
        fontFamily:'poppins',
        fontSize:'25px',
        fontWeight:600,
        width:'70%',
        justifyContent:'center',
        textAlign:'center',
    //     [theme.breakpoints.down("md")]: {
    //         fontSize:'18px',
    // }
    },
    loaderbutton:{
        justifyContent:'center',
        alignItems:'center',
        display:'flex'
    },
  

}));
