import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const CandidatelocationApi = createApi({
    reducerPath: "CandidatelocationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Candidatelocation"],
    endpoints: (builder) => ({
        getCandidatelocation: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Candidatelocation/uiforms/formattributes`,
            providesTags: ["Candidatelocation"]
        }),
        addCandidatelocation: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidatelocation/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidatelocation"]
        }),
        importCandidatelocation: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidatelocation/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidatelocation"]
        }),
        updateCandidatelocation:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Candidatelocation/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Candidatelocation"]
        }),
        filterCandidatelocation: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidatelocation/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidatelocation"]
        }),
        deleteCandidatelocation: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Candidatelocation/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Candidatelocation"]
        }),
        getCandidatelocationAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Candidatelocation/uiforms/autoCount`,
            providesTags: ["Candidatelocation"]
            })
        })
    })
export const { 
    useGetCandidatelocationQuery, useFilterCandidatelocationQuery, useAddCandidatelocationMutation, useUpdateCandidatelocationMutation, useImportCandidatelocationMutation, useDeleteCandidatelocationMutation, useGetCandidatelocationAutoCountQuery
//Dynamic query
} = CandidatelocationApi