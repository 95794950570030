import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoaderComponent } from "../../components/loader";

const SignUp = lazy(() => import("../signup/SignUp"));
const AppSetting = lazy(() => import("../appsetting/AppSetting"));
const YourappLink = lazy(() => import("../appsetting/YourappLink"));
const DefaultApp = lazy(() => import("../defaultapp/DefaultApp"));
const LandingPage = lazy(() => import("../LandingPage/Home/LandingPage"));

function Routing() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height:'100vh'
            }}
          >
            <LoaderComponent />
          </div>
        }
      >
        <Routes>
          <Route>
            <Route path="/" index element={<LandingPage />} />
            <Route path="signup" index element={<SignUp />} />
            <Route path="defaultapp" index element={<DefaultApp />} />
            <Route path="appsettings" element={<AppSetting />} />
            <Route path="yourapplink" element={<YourappLink />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routing;
