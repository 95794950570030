import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const RequisitionstateApi = createApi({
    reducerPath: "RequisitionstateApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Requisitionstate"],
    endpoints: (builder) => ({
        getRequisitionstate: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Requisitionstate/uiforms/formattributes`,
            providesTags: ["Requisitionstate"]
        }),
        addRequisitionstate: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Requisitionstate/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Requisitionstate"]
        }),
        importRequisitionstate: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Requisitionstate/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Requisitionstate"]
        }),
        updateRequisitionstate:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Requisitionstate/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Requisitionstate"]
        }),
        filterRequisitionstate: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Requisitionstate/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Requisitionstate"]
        }),
        deleteRequisitionstate: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Requisitionstate/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Requisitionstate"]
        }),
        getRequisitionstateAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Requisitionstate/uiforms/autoCount`,
            providesTags: ["Requisitionstate"]
            })
        })
    })
export const { 
    useGetRequisitionstateQuery, useFilterRequisitionstateQuery, useAddRequisitionstateMutation, useUpdateRequisitionstateMutation, useImportRequisitionstateMutation, useDeleteRequisitionstateMutation, useGetRequisitionstateAutoCountQuery
//Dynamic query
} = RequisitionstateApi