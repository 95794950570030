import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../config";
import { LocalStorageKeys } from "../../../../utils";

const baseQuery = fetchBaseQuery({
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const zerocostHRDAshboardAPI = createApi({
  reducerPath: "zerocostHRDAshboardAPI",
  baseQuery: baseQuery,
  tagTypes: ["zerocostHRDAshboardAPI"],
  endpoints: (builder) => ({
    zerocostHRDashboard: builder.mutation({
      query: (data) => ({
        url: `https://kovaionapi.azurewebsites.net/api/azureblob`,
        method: "POST",
        body: data,
      }),
    }),
    zerocostPost: builder.mutation({
      query: (data) => ({
        url: `https://kovaionapi.azurewebsites.net/api/gettoken`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
useZerocostHRDashboardMutation,useZerocostPostMutation
} = zerocostHRDAshboardAPI;
