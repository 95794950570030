import React, { useEffect } from "react";
import { Grid, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useStyles } from "./style";
import loader from "../../assets/images/loader.svg";
export const LoaderComponent = ({
  versiontitle,
  releasetype,
  versiondescription,
  refetchPermission,
}) => {
  const navigator = useNavigate();

  // useEffect(() => {
  //   refetchPermission();
  //   navigator("/versionhistory");
  // }, []);

  // const handleClick = () => {
  //   refetchPermission();
  //   navigator("/versionhistory");
  // };

  const classes = useStyles();

  return (
    <div style={{ width: "80px", height: "80px" }} className="loaderspin">
      <img src={loader} />
    </div>
  );
};
