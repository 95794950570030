import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const RecruitmentphaseApi = createApi({
    reducerPath: "RecruitmentphaseApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Recruitmentphase"],
    endpoints: (builder) => ({
        getRecruitmentphase: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Recruitmentphase/uiforms/formattributes`,
            providesTags: ["Recruitmentphase"]
        }),
        addRecruitmentphase: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruitmentphase/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruitmentphase"]
        }),
        importRecruitmentphase: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruitmentphase/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruitmentphase"]
        }),
        updateRecruitmentphase:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Recruitmentphase/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Recruitmentphase"]
        }),
        filterRecruitmentphase: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruitmentphase/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruitmentphase"]
        }),
        deleteRecruitmentphase: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Recruitmentphase/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Recruitmentphase"]
        }),
        getRecruitmentphaseAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Recruitmentphase/uiforms/autoCount`,
            providesTags: ["Recruitmentphase"]
            })
        })
    })
export const { 
    useGetRecruitmentphaseQuery, useFilterRecruitmentphaseQuery, useAddRecruitmentphaseMutation, useUpdateRecruitmentphaseMutation, useImportRecruitmentphaseMutation, useDeleteRecruitmentphaseMutation, useGetRecruitmentphaseAutoCountQuery
//Dynamic query
} = RecruitmentphaseApi