import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const RecruitmentstateApi = createApi({
    reducerPath: "RecruitmentstateApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Recruitmentstate"],
    endpoints: (builder) => ({
        getRecruitmentstate: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Recruitmentstate/uiforms/formattributes`,
            providesTags: ["Recruitmentstate"]
        }),
        addRecruitmentstate: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruitmentstate/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruitmentstate"]
        }),
        importRecruitmentstate: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruitmentstate/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruitmentstate"]
        }),
        updateRecruitmentstate:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Recruitmentstate/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Recruitmentstate"]
        }),
        filterRecruitmentstate: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Recruitmentstate/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Recruitmentstate"]
        }),
        deleteRecruitmentstate: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Recruitmentstate/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Recruitmentstate"]
        }),
        getRecruitmentstateAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Recruitmentstate/uiforms/autoCount`,
            providesTags: ["Recruitmentstate"]
            })
        })
    })
export const { 
    useGetRecruitmentstateQuery, useFilterRecruitmentstateQuery, useAddRecruitmentstateMutation, useUpdateRecruitmentstateMutation, useImportRecruitmentstateMutation, useDeleteRecruitmentstateMutation, useGetRecruitmentstateAutoCountQuery
//Dynamic query
} = RecruitmentstateApi