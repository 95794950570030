import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const JobstatusApi = createApi({
    reducerPath: "JobstatusApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Jobstatus"],
    endpoints: (builder) => ({
        getJobstatus: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Jobstatus/uiforms/formattributes`,
            providesTags: ["Jobstatus"]
        }),
        addJobstatus: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Jobstatus/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Jobstatus"]
        }),
        importJobstatus: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Jobstatus/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Jobstatus"]
        }),
        updateJobstatus:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Jobstatus/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Jobstatus"]
        }),
        filterJobstatus: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Jobstatus/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Jobstatus"]
        }),
        deleteJobstatus: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Jobstatus/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Jobstatus"]
        }),
        getJobstatusAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Jobstatus/uiforms/autoCount`,
            providesTags: ["Jobstatus"]
            })
        })
    })
export const { 
    useGetJobstatusQuery, useFilterJobstatusQuery, useAddJobstatusMutation, useUpdateJobstatusMutation, useImportJobstatusMutation, useDeleteJobstatusMutation, useGetJobstatusAutoCountQuery
//Dynamic query
} = JobstatusApi