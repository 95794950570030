import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const InterviewerApi = createApi({
    reducerPath: "InterviewerApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Interviewer"],
    endpoints: (builder) => ({
        getInterviewer: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Interviewer/uiforms/formattributes`,
            providesTags: ["Interviewer"]
        }),
        addInterviewer: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Interviewer/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Interviewer"]
        }),
        importInterviewer: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Interviewer/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Interviewer"]
        }),
        updateInterviewer:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Interviewer/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Interviewer"]
        }),
        filterInterviewer: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Interviewer/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Interviewer"]
        }),
        deleteInterviewer: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Interviewer/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Interviewer"]
        }),
        getInterviewerAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Interviewer/uiforms/autoCount`,
            providesTags: ["Interviewer"]
            })
        })
    })
export const { 
    useGetInterviewerQuery, useFilterInterviewerQuery, useAddInterviewerMutation, useUpdateInterviewerMutation, useImportInterviewerMutation, useDeleteInterviewerMutation, useGetInterviewerAutoCountQuery
//Dynamic query
} = InterviewerApi