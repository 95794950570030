import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config?.api_url + "tenant",
});

export const tenantApi = createApi({
  reducerPath: "tenantApi",
  baseQuery: baseQuery,
  tagTypes: ["Tenant"],
  endpoints: (builder) => ({
    createTenant: builder.mutation({
      query: ({ sub_domain, is_generic, client_details, admin_details }) => ({
        url: `/client/create`,
        method: "POST",
        body: {
          sub_domain: sub_domain,
          is_generic: is_generic,
          client_details: client_details,
          admin_details: admin_details,
        },
      }),
    }),
    tenantList: builder.mutation({
      query: ({ offset, limit }) => ({
        url: `/client/list`,
        method: "POST",
        body: {
          filter: {
            name: "",
            offset: offset,
            limit: limit,
          },
        },
      }),
    }),
    signupTenant: builder.mutation({
      query: ({ sub_domain, is_generic, client_details }) => ({
        url: `/client/createCustomClient`,
        method: "POST",
        body: {
          sub_domain: sub_domain,
          is_generic: is_generic,
          client_details: client_details,
        },
      }),
    }),
    publishApps: builder.mutation({
      query: ({
        client_details,
        subDomain,
        appdata,
        aiapp,
        tenantConDetails,
      }) => ({
        url: `/client/publishapps`,
        method: "POST",
        body: {
          client_details: client_details,
          subDomain,
          appdata,
          aiapp,
          tenantConDetails,
        },
      }),
    }),
    formatApp: builder.mutation({
      query: (data) => ({
        url: `/client/formatapp`,
        method: "POST",
        body: data,
      }),
    }),
    verifyOtpTenant: builder.mutation({
      query: ({ client_details, appdata, aiapp }) => ({
        url: `/client/verifyCustomClient`,
        method: "POST",
        body: {
          aiapp: aiapp,
          client_details: client_details,
          appdata: appdata,
        },
      }),
    }),
    createTenantUsingExistingClient: builder.mutation({
      query: ({ client_details, appdata, aiapp }) => ({
        url: `/client/createTenantUsingExistingClient`,
        method: "POST",
        body: {
          aiapp: aiapp,
          client_details: client_details,
          appdata: appdata,
        },
      }),
    }),
    resetOtpTenant: builder.mutation({
      query: ({ client_details }) => ({
        url: `/client/resendOtp`,
        method: "POST",
        body: {
          client_details: client_details,
        },
      }),
    }),
    mobileOTPcreate: builder.mutation({
      query: ({ client_details }) => ({
        url: `/mobileotpcreate`,
        method: "POST",
        body: {
          client_details: client_details,
        },
      }),
    }),
    mailSend: builder.mutation({
      query: (data) => ({
        url: `/mailsend`,
        method: "POST",
        body: data,
      }),
    }),
    updateContactStatus: builder.mutation({
      query: (data) => ({
        url: `/updatecontactstatus`,
        method: "PUT",
        body: data,
      }),
    }),
    getWhatsappUser: builder.query({
      query: ({ mobile }) => ({
        url: `/getwhatsappuser/${mobile}`,
        method: "GET",
      }),
    }),
    getBuildVersion: builder.query({
      query: () => ({
        url: `/client/getVersionHistory`,
        method: "GET",
      }),
    }),
    logoData: builder.mutation({
      query: (data) => ({
        url: `/client/dynamicstyle`,
        method: "POST",
        body: data,
      }),
    }),
    getlogoData: builder.query({
      query: (subDomain) => ({
        url: `/client/getdynamicstyle/${subDomain}`,
        method: "GET",
      }),
    }),
    getLoginType: builder.query({
      query: (subDomain) => ({
        url: `/client/getlogintype/${subDomain}`,
        method: "GET",
      }),
    }),
    getAdminDetails: builder.query({
      query: (data) => ({
        url: `/client/getadmindetails/`,
        method: "POST",
        body: data,
      }),
    }),
    otplogin: builder.mutation({
      query: (data) => ({
        url: `/client/createotpbaselogin`,
        method: "POST",
        body: data,
      }),
    }),
    getGenericApps: builder.mutation({
      query: (data) => ({
        url: `/client/getgenericapps`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useCreateTenantMutation,
  useTenantListMutation,
  useSignupTenantMutation,
  useGetAdminDetailsQuery,
  useVerifyOtpTenantMutation,
  useCreateTenantUsingExistingClientMutation,
  useResetOtpTenantMutation,
  useLogoDataMutation,
  useMailSendMutation,
  useUpdateContactStatusMutation,
  useGetWhatsappUserQuery,
  useMobileOTPcreateMutation,
  useGetlogoDataQuery,
  useGetLoginTypeQuery,
  useOtploginMutation,
  useGetGenericAppsMutation,
  usePublishAppsMutation,
  useFormatAppMutation,
  useGetBuildVersionQuery,
} = tenantApi;
