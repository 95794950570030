import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LocalStorageKeys } from "../../../../utils";
import { config } from "../../../../config";

export const RequisitionApi = createApi({
  reducerPath: "RequisitionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LocalStorageKeys.authToken);
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Requisition"],
  endpoints: (builder) => ({
    getRequisition: builder.query({
      query: () =>
        `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/formattributes`,
      providesTags: ["Requisition"],
    }),
    addRequisition: builder.mutation({
      query: (data) => ({
        url: "/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Requisition"],
    }),
    importRequisition: builder.mutation({
      query: (data) => ({
        url: "/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/import",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Requisition"],
    }),
    updateRequisition: builder.mutation({
      query: (data) => ({
        url: `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Requisition"],
    }),
    filterRequisition: builder.query({
      query: (data) => ({
        url:
          "/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/filterdata",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Requisition"],
    }),
    deleteRequisition: builder.mutation({
      query: (id) => ({
        url: `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Requisition"],
    }),
    getRequisitionAutoCount: builder.query({
      query: () =>
        `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/autoCount`,
      providesTags: ["Requisition"],
    }),
    getRequisitionDashboardCount: builder.query({
      query: (data) => ({
        url: `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/dashboardCount`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Requisition"],
    }),
    filterDashboard: builder.query({
      query: (data) => ({
        url:
          "/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/filterDashboarddata",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Requisition"],
    }),
    filterDashboardExport: builder.query({
      query: (data) => ({
        url:
          "/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/filterDashboardExportdata",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Requisition"],
    }),
    getFormDashBoard: builder.query({
      query: (data) => ({
        url: `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/Formdashboard`,
        method: "POST",
        body: data,
      }),
      // query: () =>
      //   `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/Formdashboard`,
      providesTags: ["Requisition"],
    }),
    getHrPerformanceManagement: builder.query({
      query: (data) => ({
        url: `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/HrPerformance`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Requisition"],
    }),
    getRequisitionHistory: builder.query({
      query: (id) =>
        `/Defaultapplications/HR_RECRUITMENT/Requisition/uiforms/job/${id}`,
      providesTags: ["Requisition"],
    }),
  }),
});
export const {
  useGetRequisitionQuery,
  useFilterRequisitionQuery,
  useAddRequisitionMutation,
  useUpdateRequisitionMutation,
  useImportRequisitionMutation,
  useDeleteRequisitionMutation,
  useGetRequisitionAutoCountQuery,
  useGetRequisitionDashboardCountQuery,
  useGetFormDashBoardQuery,
  useGetRequisitionHistoryQuery,
  useGetHrPerformanceManagementQuery,
  useFilterDashboardQuery,
  useFilterDashboardExportQuery,
  //Dynamic query
} = RequisitionApi;
