import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LocalStorageKeys } from "../../../../utils";
import { config } from "../../../../config";

export const OpenAiApi = createApi({
  reducerPath: "OpenAiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.api_url + "openai",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LocalStorageKeys.authToken);
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["OpenAi"],
  endpoints: (builder) => ({
    getOpenAiResponse: builder.mutation({
      query: (data) => ({
        url: "/openairesponse",
        method: "POST",
        body: {
          message: data.message,
          openAiText: data.openAiText,
          appendDirection: data.appendDirection,
          componentid: data.componentid,
        },
      }),
      invalidatesTags: ["OpenAiApi"],
    }),
    getOpenAiResponseNa: builder.mutation({
      query: (data) => ({
        url: "/openairesponsena",
        method: "POST",
        body: {
          message: data.message,
          openAiText: data.openAiText,
          appendDirection: data.appendDirection,
          componentid: data.componentid,
          code: data.code,
        },
      }),
      invalidatesTags: ["OpenAiApi"],
    }),
    storeOpenAiCredentials: builder.mutation({
      query: (data) => ({
        url: "/openaicredentials",
        method: "POST",
        body: {
          openAiKey: data.openAiKey,
          organizationKey: data.organizationKey,
        },
      }),
      invalidatesTags: ["OpenAiApi"],
    }),
    getOpenAiCredentials: builder.mutation({
      query: (data) => ({
        url: "/getopenaicredentials",
        method: "POST",
        body: {},
      }),
      providesTags: ["OpenAiApi"],
    }),
  }),
});
export const {
  useGetOpenAiResponseMutation,
  useStoreOpenAiCredentialsMutation,
  useGetOpenAiCredentialsMutation,
  useGetOpenAiResponseNaMutation,
  //Dynamic query
} = OpenAiApi;
