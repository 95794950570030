import React from "react";
import "./assets/scss/style.scss";
import "./assets/scss/layout.scss";
import "./assets/scss/responsive.scss";
import { AppRedux } from "./redux";
import { SnackbarProvider } from "notistack";
import Routing from "./views/Route/Routing";

export default function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <AppRedux>
        <Routing />
      </AppRedux>
    </SnackbarProvider>
  );
}
