import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LocalStorageKeys } from "../../../../utils";
import { config } from "../../../../config";

export const RecruitmentApi = createApi({
  reducerPath: "RecruitmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = localStorage.getItem(LocalStorageKeys.authToken);
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Recruitment"],
  endpoints: (builder) => ({
    getRecruitment: builder.query({
      query: () =>
        `/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/formattributes`,
      providesTags: ["Recruitment"],
    }),
    addRecruitment: builder.mutation({
      query: (data) => ({
        url: "/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Recruitment"],
    }),
    importRecruitment: builder.mutation({
      query: (data) => ({
        url: "/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/import",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Recruitment"],
    }),
    updateRecruitment: builder.mutation({
      query: (data) => ({
        url: `/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Recruitment"],
    }),
    filterRecruitment: builder.query({
      query: (data) => ({
        url: "/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/filterdata",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Recruitment"],
    }),
    filterExport: builder.query({
      query: (data) => ({
        url: "/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/filterexportdata",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Recruitment"],
    }),
    deleteRecruitment: builder.mutation({
      query: (id) => ({
        url: `/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Recruitment"],
    }),
    getRecruitmentAutoCount: builder.query({
      query: () =>
        `/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/autoCount`,
      providesTags: ["Recruitment"],
    }),
    getRecruitmentHistory: builder.query({
      query: (id) =>
        `/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/${id}`,
      providesTags: ["Recruitment"],
    }),
    ContractUserCreate: builder.mutation({
      query: ({
        name = "",
        phone = "",
        email = "",
        emp_id = "",
        role = "",
        reporting_to = "",
        is_active = "",
        id = "",
        entity_id,
        entity_name = "",
        extraColumn = [],
        img_url = "",
        country_code = "",
      }) => ({
        url: `/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/ContractUserCreate`,
        method: "POST",
        body: {
          name,
          phone,
          email,
          emp_id,
          role,
          reporting_to,
          is_active,
          _id: id,
          entity_id,
          entity_name,
          extraColumn,
          img_url,
          country_code,
        },
      }),
    }),
    workflowActiveData: builder.query({
      query: (body) =>
        `/Defaultapplications/HR_RECRUITMENT/Recruitment/uiforms/workflowexecute?formName=${body.formName}&id=${body.id}`,
      providesTags: ["Recruitment"],
    }),
  }),
});
export const {
  useGetRecruitmentQuery,
  useFilterRecruitmentQuery,
  useAddRecruitmentMutation,
  useUpdateRecruitmentMutation,
  useImportRecruitmentMutation,
  useDeleteRecruitmentMutation,
  useGetRecruitmentAutoCountQuery,
  useGetRecruitmentHistoryQuery,
  useContractUserCreateMutation,
  useWorkflowActiveDataQuery,
  useFilterExportQuery
  //Dynamic query
} = RecruitmentApi;
