import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const CandidatesApi = createApi({
    reducerPath: "CandidatesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Candidates"],
    endpoints: (builder) => ({
        getCandidates: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/formattributes`,
            providesTags: ["Candidates"]
        }),
        addCandidates: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidates"]
        }),
        importCandidates: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidates"]
        }),
        updateCandidates:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Candidates"]
        }),
        filterCandidates: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidates"]
        }),
        deleteCandidates: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Candidates"]
        }),
        getCandidatesAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/autoCount`,
            providesTags: ["Candidates"]
        }),
        getCandidatesHistory: builder.query({
            query: (id) => `/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/candidate/${id}`,
            providesTags: ["Candidates"]

            }),
            getCandidateByMail: builder.query({
                query: (email) => ({
                    url: "/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/getOnboarding",
                    method: 'POST',
                    body: email
                }),
                invalidatesTags: ["Candidates"]
            }),
            addOnBoadrCandidates: builder.mutation({
                query: (data) => ({
                    url: `/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/updateOnboard/${data.id}`,
                    method: 'POST',
                    body:data
                }),
                invalidatesTags: ["Candidates"]
            }),
            sendOnboardDoneMail: builder.mutation({
                query: (data) => ({
                    url: `/Defaultapplications/HR_RECRUITMENT/Candidates/uiforms/OnboardMailReport`,
                    method: 'POST',
                    body:data
                }),
                invalidatesTags: ["Candidates"]
            }),
           
        })
    })
export const { 
    useGetCandidatesQuery, useFilterCandidatesQuery, useAddCandidatesMutation, useUpdateCandidatesMutation, useImportCandidatesMutation, useDeleteCandidatesMutation, useGetCandidatesAutoCountQuery, useGetCandidatesHistoryQuery,useGetCandidateByMailQuery,useAddOnBoadrCandidatesMutation,useSendOnboardDoneMailMutation
//Dynamic query
} = CandidatesApi