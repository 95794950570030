import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const CandidateskillsApi = createApi({
    reducerPath: "CandidateskillsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Candidateskills"],
    endpoints: (builder) => ({
        getCandidateskills: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Candidateskills/uiforms/formattributes`,
            providesTags: ["Candidateskills"]
        }),
        addCandidateskills: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidateskills/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidateskills"]
        }),
        importCandidateskills: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidateskills/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidateskills"]
        }),
        updateCandidateskills:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Candidateskills/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Candidateskills"]
        }),
        filterCandidateskills: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Candidateskills/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Candidateskills"]
        }),
        deleteCandidateskills: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Candidateskills/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Candidateskills"]
        }),
        getCandidateskillsAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Candidateskills/uiforms/autoCount`,
            providesTags: ["Candidateskills"]
            })
        })
    })
export const { 
    useGetCandidateskillsQuery, useFilterCandidateskillsQuery, useAddCandidateskillsMutation, useUpdateCandidateskillsMutation, useImportCandidateskillsMutation, useDeleteCandidateskillsMutation, useGetCandidateskillsAutoCountQuery
//Dynamic query
} = CandidateskillsApi