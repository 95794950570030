import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorageKeys } from '../../../../utils';
import { config } from '../../../../config';

export const SourceApi = createApi({
    reducerPath: "SourceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config.api_url,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken)
            if (token) {
              headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
          }
    }),
    tagTypes: ["Source"],
    endpoints: (builder) => ({
        getSource: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Source/uiforms/formattributes`,
            providesTags: ["Source"]
        }),
        addSource: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Source/uiforms/create",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Source"]
        }),
        importSource: builder.mutation({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Source/uiforms/import",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Source"]
        }),
        updateSource:builder.mutation({
            query: (data) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Source/uiforms/${data._id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Source"]
        }),
        filterSource: builder.query({
            query: (data) => ({
                url: "/Defaultapplications/HR_RECRUITMENT/Source/uiforms/filterdata",
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Source"]
        }),
        deleteSource: builder.mutation({
            query: (id) => ({
                url: `/Defaultapplications/HR_RECRUITMENT/Source/uiforms/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Source"]
        }),
        getSourceAutoCount: builder.query({
            query: () => `/Defaultapplications/HR_RECRUITMENT/Source/uiforms/autoCount`,
            providesTags: ["Source"]
            })
        })
    })
export const { 
    useGetSourceQuery, useFilterSourceQuery, useAddSourceMutation, useUpdateSourceMutation, useImportSourceMutation, useDeleteSourceMutation, useGetSourceAutoCountQuery
//Dynamic query
} = SourceApi